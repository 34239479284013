import { Canvas } from "@react-three/fiber";
import { noiseBgShader } from "./shaders/noise";
import { Scene } from "./three/bgScene";

function App() {
  return (
    <div className="bg-black h-dvh w-dvw">
      <Canvas className="bg-black h-dvh w-dvw">
        <Scene
          vertex={noiseBgShader.vertexShader}
          fragment={noiseBgShader.fragmentShader}
        />
      </Canvas>
      <div className="absolute top-0 left-0 z-10 text-white flex justify-center items-center w-full h-full flex-col gap-4 pointer-events-none">
        <h1 className="text-7xl font-mono font-thin mb-4">ryder</h1>
        <footer className="flex flex-col gap-4 justify-center text-center opacity-75 font-mono">
          <a
            href="https://github.com/ryderdonahue"
            className="pointer-events-auto  hover:underline"
          >
            linkedin
          </a>
          <a
            href="https://github.com/ryderdonahue"
            className="pointer-events-auto  hover:underline"
          >
            github
          </a>
        </footer>
      </div>
    </div>
  );
}

export default App;
