export const noiseBgShader = {
  vertexShader: `
    void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`,
  fragmentShader: `
    uniform float iTime;
    uniform vec2 iResolution;
    uniform sampler2D iChannel0;
    uniform float fill;
    uniform float density;
    uniform float metallic;

    
    float noise3( vec3 x ) {
        vec3 p = floor(x),f = fract(x);
    
        f = f*f*(3.-2.*f);  // or smoothstep     // to make derivative continuous at borders
    
    #define hash3(p)  fract(sin(1e3*dot(p,vec3(1,57,-13.7)))*4375.5453)        // rand
        
        return mix( mix(mix( hash3(p+vec3(0,0,0)), hash3(p+vec3(1,0,0)),f.x),       // triilinear interp
                        mix( hash3(p+vec3(0,1,0)), hash3(p+vec3(1,1,0)),f.x),f.y),
                    mix(mix( hash3(p+vec3(0,0,1)), hash3(p+vec3(1,0,1)),f.x),       
                        mix( hash3(p+vec3(0,1,1)), hash3(p+vec3(1,1,1)),f.x),f.y), f.z);
    }
    
    #define noise(x) (noise3(x)+noise3(x+11.5)) / 2. // pseudoperlin improvement from foxes idea
    
    void main() // ------------ draw isovalues
    {
        vec2 U = gl_FragCoord.xy;
        // float density = 2.;
        // float fill = 128.;
        // float metallic = 0.0;
        float t = iTime * .2;
        vec2 R = iResolution.xy;
        float n = noise(vec3(U * density / R.y, .1 * t));
        float v = sin(fill * n);
        
        v = smoothstep(1.0, metallic, 0.3 * abs(v) / fwidth(v));
        
        vec4 color = mix(exp(-720. / R.y) * texture(iChannel0, (U + vec2(1, sin(t))) / R),
                         vec4(0.0) + 0.2 * sin(360.0 * n + vec4(0.1, 0.1, 0.1, 0.0)),
                         v);
        gl_FragColor = mix(color, vec4(.0),abs(U.x - R.x) / R.x);
    }`,
};
