import { useFrame, useThree } from "@react-three/fiber";
import { useMemo } from "react";
import * as THREE from "three";

const generateNoiseTexture = (width: number, height: number) => {
  const size = width * height;
  const data = new Uint8Array(size);

  for (let i = 0; i < size; i++) {
    data[i] = Math.random() * 255;
  }

  const texture = new THREE.DataTexture(
    data,
    width,
    height,
    THREE.LuminanceFormat
  );
  texture.needsUpdate = true;
  return texture;
};
export const Scene = ({
  vertex,
  fragment,
}: {
  vertex: string;
  fragment: string;
}) => {
  const noiseTexture = useMemo(() => generateNoiseTexture(256, 256), []);
  const { size } = useThree();

  const uniforms = useMemo(
    () => ({
      iTime: {
        type: "f",
        value: 1.0,
      },
      iResolution: {
        type: "v2",
        value: new THREE.Vector2(size.width, size.height),
      },
      iChannel0: {
        type: "t",
        value: noiseTexture,
      },
      fill: {
        type: "f",
        value: 128.0,
      },

      density: {
        type: "f",
        value: 2.0,
      },
      metallic: {
        type: "f",
        value: 0.0,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noiseTexture]
  );

  useFrame((state, delta) => {
    uniforms.iTime.value += delta + state.pointer.x / 10;
    // make fill based on mouse position
    // uniforms.fill.value = 128 + Math.abs(state.pointer.y * 2);
    // uniforms.metallic.value = 0 + Math.abs(state.pointer.y);
  });

  return (
    <mesh>
      <planeGeometry args={[size.width, size.height]} />
      <shaderMaterial
        uniforms={uniforms}
        vertexShader={vertex}
        fragmentShader={fragment}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};
